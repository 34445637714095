<template>
  <component :is="wrapper" v-bind="mergedAttrs" ref="wrapperElement" style="">
    <div class="block-background" v-if="hasBackground" ref="backgroundElement" 
    :style="props?.options?.advanced_options?.enable_background && props?.options?.advanced_options?.background?.type === 'COLOR' 
    ? { background: props?.options?.advanced_options?.background?.overlay?.color_custom } 
    : {}">
      <div class="media-rail">
        <div class="background-top-resizer">
          <div class="background-bottom-resizer">
            <Media :data="props?.options?.advanced_options?.background" />
          </div>
        </div>
        <div class="transition-gradient transition-gradient-top"
             v-if="hasAdvancedSettings && ['top', 'both'].includes(props?.options?.advanced_options?.background_gradient)"
             :style="{background: generateTransitionGradient(props?.options?.advanced_options?.background_gradient_color)}"></div>
        <div class="transition-gradient transition-gradient-bottom"
             v-if="hasAdvancedSettings && ['bottom', 'both'].includes(props?.options?.advanced_options?.background_gradient)"
             :style="{background: generateTransitionGradient(props?.options?.advanced_options?.background_gradient_color, 'to top')}"></div>
      </div>
    </div>
    <div :class="contentClasses" :style="contentStyle">
      <slot></slot>
    </div>
  </component>
</template>

<script setup lang="ts">
import { useAttrs, computed, ref, onMounted, onBeforeUnmount } from 'vue';
import Media from '~/components/common/Media.vue';
import gsap from "gsap";

const props = defineProps({
  wrapper: {
    type: String,
    default: 'section'
  },
  options: {
    type: Object,
    default: () => ({})
  }
})

const containerClasses = {
  "full-screen": "qc-container-fullscreen",
  "full-screen-exclude-mobile": "qc-container-fullscreen-exclude-mobile",
  "full-screen-exclude-header": "qc-container-fullscreen-exclude-header",
  "qc-container-fullscreen-partial-top": "qc-container-fullscreen-partial-top",
  "3xl": "qc-container-3xl",
  "qc-container-xl": "qc-container-xl",
}

const backgroundElement = ref(null);
const wrapperElement = ref(null);
const attrs = useAttrs()

const hasAdvancedSettings = computed(() => {
  return !!props?.options?.enable_advanced_options
})

const hasBackground = computed(() => {
  if (hasAdvancedSettings.value)
    return !!props?.options?.advanced_options?.enable_background;
  return false;
})

const generateTransitionGradient = (color: string, direction = "to bottom") => {
  return  "linear-gradient(" + direction + ", "+ color +", transparent)";
}

const contentClasses = computed(() => {
  const classes = ['block-content'];

  if (hasAdvancedSettings.value) {
    classes.push('alignment-' + props?.options?.advanced_options?.default?.type_of_alignment);

    if (!!props?.options?.advanced_options?.default?.container)
      classes.push(containerClasses[props?.options?.advanced_options?.default?.container]);
  }
  return classes;
});

const contentStyle = computed(() => {
  const styles: Record<string, string> = {};
  
  if (hasAdvancedSettings.value) {
      styles['color'] = props.options?.advanced_options?.default?.default_text_color
  }
  
  return styles;
});

const mergedAttrs = computed(() => {
  const classes = ['block-wrapper'];
  const result = {...attrs};
  if (props?.options?.advanced_options?.fixed_background) {
    classes.push('fixed-background');
  }
  

  if (!!props?.options?.advanced_options?.default?.effect)
    classes.push('animation-' + props?.options?.advanced_options?.default?.effect);
  result['class'] = [attrs.class, ...classes].filter(Boolean).join(' ');
  if(props?.options?.advanced_options?.data_anchor){
    result['data-anchor'] = props?.options?.advanced_options?.data_anchor;
  }
  return result


})

const gsapInstances = [];

onMounted(() => {
  if (backgroundElement.value && props?.options?.advanced_options?.default?.effect === 'background-fade-on-exit') {
    gsapInstances.push(gsap.to(backgroundElement.value, {
      opacity: 0,
      ease: "none",
      scrollTrigger: {
        trigger: backgroundElement.value,
        start: "bottom bottom",
        end: "bottom center",
        scrub: true,
      }
    }))
  }
  if (backgroundElement.value && props?.options?.advanced_options?.default?.effect === 'background-appear-on-enter') {
    gsapInstances.push(gsap.from(backgroundElement.value, {
      opacity: 0,
      ease: "none",
      scrollTrigger: {
        trigger: backgroundElement.value,
        start: "top center",
        end: "top top",
        scrub: true,
      }
    }))
  }

  if (props?.options?.enable_advanced_options && props?.options?.advanced_options?.default?.effect === 'follow-on-exit') {
    //manage opacity
    gsapInstances.push(gsap.to(wrapperElement.value, {
      opacity: 0,
      ease: "none",
      scrollTrigger: {
        trigger: wrapperElement.value,
        start: `top+=${window.innerHeight / 4}`,
        end: () => `+=${window.innerHeight}`,
        scrub: true,
      }
    }))
    //manage pinned
    gsapInstances.push(gsap.to(wrapperElement.value, {
      ease: "none",
      scrollTrigger: {
        trigger: wrapperElement.value,
        pin: true,
        pinSpacing: false,
        start: "bottom bottom",
        end: () => `+=${window.innerHeight}`,
        scrub: true,
      }
    }))
  }

  if (props?.options?.enable_advanced_options && props?.options?.advanced_options?.default?.effect === 'square-on-enter') {
    gsapInstances.push(gsap.from(wrapperElement.value, {
      borderRadius: "24px",
      ease: "none",
      scrollTrigger: {
        trigger: wrapperElement.value,
        start: "top bottom",
        end: `bottom bottom`,
        scrub: true,
      }
    }))
  }

  if (backgroundElement.value && props?.options?.advanced_options?.fixed_background) {
    const backgroundMedia = backgroundElement.value.querySelector('.media-rail');
    const backgroundTopResizer = backgroundElement.value.querySelector('.background-top-resizer');
    const backgroundBottomResizer = backgroundElement.value.querySelector('.background-bottom-resizer');
    gsapInstances.push(
      gsap.to(backgroundMedia, {
      ease: "none",
      scrollTrigger: {
        trigger: backgroundMedia,
        start: "top bottom",
        end: () => `+=${backgroundElement.value?.clientHeight}`,
        pin: true,
        scrub: true,
      }
    }))
    gsapInstances.push(
      gsap.from(backgroundTopResizer, {
        height: 0,
        ease: "none",
        scrollTrigger: {
          trigger: wrapperElement.value,
          start: "top bottom", //top of section reaches the bottom of viewport
          end: "top top",
          scrub: true,
        }
    }))
    gsapInstances.push(
      gsap.to(backgroundBottomResizer, {
        height: 0,
        ease: "none",
        scrollTrigger: {
          trigger: wrapperElement.value,
          start: "bottom bottom",
          end: "bottom top",
          scrub: true,
        }
    }))
  }
});

onBeforeUnmount(() => {
  if (backgroundElement.value) {
    gsapInstances.forEach((instance) => {
      instance.kill();
    });
  }
});
</script>
